<template>
    <div id="layout">
        <div id="side" :class="{visible: currentPointId}">
          <div @click="this.currentPointId = null" class="side-close-button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
          </div>
          <div class="point-info" v-if="currentPointId">
            <h1>
              {{ featureTitle(currentPoint.properties) }}
              <pre>{{ currentPoint.properties.shom_id }} • {{ currentPoint.properties.dam_id }}</pre>
            </h1>
            <div class="point-properties" v-html="propertiesHtml"></div>
            <div class="light" v-if="currentPoint.properties.light" style="">
              <h2>Feu</h2>
              <div :key="key" v-for="(prop, key) in currentPointLight">
                <b>{{ key }}</b>{{ prop }}
              </div>
            </div>
          </div>
        </div>
        <div class="map-controller controller">
            <div id="map"></div>
            <div v-show="isLoading" class="map-loader">Chargement en cours...</div>
        </div>
    </div>

</template>

<script>

import axios from 'axios'
import _ from 'lodash'
// import md5 from 'md5'
import layers from '../data/layers.js'
import glossary from '../data/glossary.js'

export default {
    name: 'MapController',
    components: {},
    data() {
        return {
            isLoading: true,
            currentPointId: null,
            hoveredPointId: null,
        };
    },
    created() {
    },
    mounted() {
        // this.fetchBaseMapStyle().then(this.initMap);
        this.initMap();
    },
    unmounted() {
        this.map.remove();
    },
    computed: {
        propertiesHtml() {
            var output = '';
            _.forEach(this.currentPoint.properties, (prop, key) => {
                if (['type', 'light'].indexOf(key) === -1) {
                    output += '<div>'
                    if (typeof key === 'string' || key instanceof String) {
                        let glossaryIndex = key.toUpperCase();

                        if (glossary[glossaryIndex]) {
                            output += '<b>' + glossary[glossaryIndex][0] + '</b>';
                        }
                        else {
                            if (key == 'dam_id') {
                                key = 'Identifiant DAM / Phares et Balises';
                            }
                            if (key == 'shom_id') {
                                key = 'Identifiant SHOM';
                            }
                            if (key == 'type_name') {
                                key = 'Type';
                            }
                            output += '<b>' + key + '</b>';
                        }

                        if (glossary[glossaryIndex] && Object.keys(glossary[glossaryIndex]).length > 1) {
                          if (key == 'colour') {
                              output+= prop.split(',').map((val) => {
                                  return glossary[glossaryIndex][val];
                              }).join(', ');
                          }
                          else {
                              output += glossary[glossaryIndex][prop];
                          }
                        }
                        else {
                            if (prop === 0 || prop === 1) {
                                output += (prop == 1) ? 'Oui' : 'Non';
                            }
                            else {
                                output += prop;
                            }
                        }
                    }
                    output += '</div>'
                }
            })
            var source = _.cloneDeep(this.currentPoint.properties);
            delete source['type_name'];
            delete source['type'];
            delete source['light'];
            output += '<b>Données brutes SHOM</b>';
            output += '<pre>' + JSON.stringify(source, null, 4) + '</pre>'
            return output;
        },
        hoveredPoint() {
            if (this.isLoading) {
                return {}
            }
            let points = this.map.querySourceFeatures('supports', {
                filter: ['==', ['get', 'shom_id'], this.hoveredPointId]
            });
            return points[0];
        },
        currentPoint() {
            if (this.isLoading) {
                return {}
            }
            let points = this.map.querySourceFeatures('supports', {
                filter: ['==', ['get', 'shom_id'], this.currentPointId]
            });
            return points[0];
        },
        currentPointLight() {
          if (this.currentPoint.properties.light) {
            let data = JSON.parse(this.currentPoint.properties.light);
            return data;
          }
          return {};
        },
        filters() {
            let filters = [];
            let highlightFilters = [];
            let hoverFilters = [];
            let shadowFilters = [];

            if (this.hoveredPointId && this.hoveredPointId != this.currentPointId) {
                filters.push(['!=', 'id', this.hoveredPointId]);
                hoverFilters.push(['==', 'id', this.hoveredPointId]);
            }
            else {
                filters.push(['!=', 'id', '']);
                hoverFilters.push(['==', 'id', '']);
            }

            if (this.currentPointId) {
                filters.push(['!=', 'id', this.currentPointId]);
                highlightFilters.push(['==', 'id', this.currentPointId]);
            }
            else {
                filters.push(['!=', 'id', '']);
                highlightFilters.push(['==', 'id', '']);
            }

            if (this.currentTerm) {
                shadowFilters.push(['has', this.currentTerm.slug]);
                filters.push(['has', this.currentTerm.slug]);
                highlightFilters.push(['has', this.currentTerm.slug]);
            }

            if (shadowFilters.length == 1) {
                shadowFilters = shadowFilters[0];
            } else {
                shadowFilters = ['all', ...shadowFilters];
            }

            if (filters.length == 1) {
                filters = filters[0];
            } else {
                filters = ['all', ...filters];
            }

            if (highlightFilters.length == 1) {
                highlightFilters = highlightFilters[0];
            } else {
                highlightFilters = ['all', ...highlightFilters];
            }

            if (hoverFilters.length == 1) {
                hoverFilters = hoverFilters[0];
            } else {
                hoverFilters = ['all', ...hoverFilters];
            }

            return {
                'point-shadow': shadowFilters,

                'point': filters,
                'monument': filters,
                'monument-stroke': filters,

                'point-highlight': highlightFilters,
                'monument-highlight': highlightFilters,
                'monument-stroke-highlight': highlightFilters,

                'point-hover': hoverFilters,
                'monument-hover': hoverFilters,
                'monument-stroke-hover': hoverFilters,
            }
        },
    },
    methods: {
        featureTitle(properties) {
            var title = '';
            if (properties.type.indexOf(['monument']) !== -1) {
                title = (properties.objnam) ? properties.objnam : properties.type_name;
            }
            else {
                if (properties.objnam) {
                    title += properties.objnam + ' - ';
                }
                title += properties.type_name;
            }
            return title;
        },
        fetchBaseMapStyle() {
            // let apiUrl = 'https://api.mapbox.com/styles/v1/' + this.params.style + '?access_token=' + this.accessToken;
            // let apiUrl = 'mapbox://styles/gregoiredavid/ckgau6b7i01pp19k557n0f6rt/draft';
            let apiUrl = 'mapbox://styles/gregoiredavid/ckgau6b7i01pp19k557n0f6rt';
            return new Promise((resolve, reject) => {
                axios.get(apiUrl)
                .then((response) => {
                    this.baseMapStyle = response.data;
                    resolve(response.data);
                })
                .catch(reject);
            });
        },
        getMapStyle() {
            let mapStyle = _.cloneDeep(this.baseMapStyle);
            _.each(this.params.sources, (data, key) => {
                mapStyle.sources[key] = {type: 'geojson', data};
            });
            mapStyle.layers.push(...layers);

            return mapStyle;
        },
        updateMapStyle() {
            this.map.setStyle(this.getMapStyle());
        },
        initMap() {
            window.mapboxgl.accessToken = 'pk.eyJ1IjoiZ3JlZ29pcmVkYXZpZCIsImEiOiJJeVdqS0t3In0.WniHU6EAaP3R4e6aOtqL4g';
            this.map = new window.mapboxgl.Map({
                container: 'map',
                // style: 'mapbox://styles/gregoiredavid/ckgau6b7i01pp19k557n0f6rt/draft',
                style: 'mapbox://styles/gregoiredavid/ckgau6b7i01pp19k557n0f6rt',
            });

            this.map.on('load', this.onMapLoad);
        },
        onMapLoad() {
            this.popup = new window.mapboxgl.Popup({
                closeButton: false,
                // closeOnClick: false
            });

            return new Promise((resolve, reject) => {
                axios.get('supports.json')
                .then((response) => {
                    console.log(response.data);
                    this.map.addSource('supports', {
                        type: 'geojson',
                        data: response.data
                    });
                    this.map.addLayer(
                      {
                        'id': 'supports-layer',
                        'type': 'circle',
                        'source': 'supports',
                        // 'source-layer': 'states',
                        'paint': {
                            'circle-color': [
                                  'match',
                                  ['get', 'type'],
                                  'balise-cardinale', '#FFC107',
                                  'balise-danger-isole', '#FFC107',
                                  'balise-laterale', '#FFC107',
                                  'balise-speciale', '#FFC107',

                                  'fort', '#FF5722',
                                  'monument', '#FF5722',
                                  'poteaux-et-pieux', '#FF5722',
                                  'reservoirs-et-silos', '#FF5722',

                                  'bouee-cardinale', '#FFEB3B',
                                  'bouee-danger-isole', '#FFEB3B',
                                  'bouee-eau-saine', '#FFEB3B',
                                  'bouee-laterale', '#FFEB3B',
                                  'bouee-speciale', '#FFEB3B',
                                  'dispositif-amarrage', '#CDDC39',
                                  'marque-de-jour', '#CDDC39',
                                  /* other */ '#ccc'
                            ],
                            "circle-blur": [
                                "interpolate", ["linear"], ["zoom"],
                                // zoom is 5 (or less) -> circle radius will be 1px
                                0, 3,
                                7, 1,
                                10, 0,
                                // zoom is 10 (or greater) -> circle radius will be 5px
                                22, 0
                            ],
                            "circle-radius": [
                                "interpolate", ["linear"], ["zoom"],
                                // zoom is 5 (or less) -> circle radius will be 1px
                                0, 0.5,
                                8, 1.5,
                                // zoom is 10 (or greater) -> circle radius will be 5px
                                22, 16
                            ],
                            'circle-opacity': .75,
                        }
                      },
                      'waterway-label'
                    );
                    this.map.on('mouseenter', 'supports-layer', this.onMouseEnter);
                    this.map.on('mouseleave', 'supports-layer', this.onMouseLeave);
                    // this.map.on('click', 'supports-layer', this.onClick);
                    this.map.on('click',  this.onClick);
                    this.map.on('zoomend', () => {
                        console.log(this.map.getZoom());
                    });

                    this.isLoading = false;
                })
                .catch(reject);
            });
        },
        oldOnMapLoad() {
            // this.updateMapStyle();

            // this.$store.watch(state => state.currentPoint, this.updateCamera);
            // this.$store.watch(state => state.currentPoint, this.updateFilters);
            // this.$store.watch(state => state.hoveredPoint, this.updateFilters);
            // this.$store.watch(state => state.taxo.currentTermId, this.updateFilters);

            // this.map.on('mouseleave', 'monument-hover', this.onMouseLeave);
            // this.map.on('mousemove', 'monument', this.onMouseMoveMonument);
            // this.map.on('mouseleave', 'monument-hover', this.onMouseLeaveMonument);
            // this.map.on('mousemove', 'point', this.onMouseMoveMonument);
            // this.map.on('mouseleave', 'point-hover', this.onMouseLeavePointMonument);
        },
        updateCamera() {
            if (this.currentPoint) {
                let centroid = this.getContent(this.currentPoint).centroid;
                this.map.easeTo({
                // this.map.flyTo({
                    zoom: (this.map.getZoom() < 16.5) ? 16.5 : this.map.getZoom(),
                    bearing: 0,
                    pitch: 0,
                    center: JSON.parse(centroid),
                    offset: [0, -120],
                });
            }
            // this.map.fitBounds([-1.6831398010253906, 48.10932245765956, -1.6759729385375977, 48.11243143831469], {padding: 10});
        },
        updateFilters() {
            _.each(this.filters, (filter, layer) => {
                this.map.setFilter(layer, filter);
            });
        },
        onClick(e) {
            let features = this.map.queryRenderedFeatures(e.point);
            // let customLayers = layers.map((l) => l.id);
            // let features = this.map.queryRenderedFeatures(e.point, {layers: customLayers});
            // if (!features.length) return;
            // this.$store.dispatch('onMapClick', features[0]);
            this.currentPointId = features[0].properties.shom_id;
        },
        onMouseLeave() {
            this.popup.remove();
            this.map.getCanvas().style.cursor = '';
            this.setHoveredPointId(null);
        },
        onMouseEnter(e) {
            if (e.features.length > 0) {
                this.map.getCanvas().style.cursor = 'pointer';
                if (!this.hoveredPointId || this.hoveredPointId != e.features[0].properties.id) {
                    this.setHoveredPointId(e.features[0].properties.id);
                    var feature = e.features[0];
                    var coordinates = e.features[0].geometry.coordinates.slice();
                    var description = '';
                    description += '<b>' + this.featureTitle(feature.properties) + '</b>';
                    description += '<pre>' + feature.properties.shom_id + ' • ' + feature.properties.dam_id + '</pre>'
                    this.popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
                }
            }
        },
        setHoveredPointId(id) {
            this.hoveredPointId = id;
        },
    },
}

</script>

<style>

/*
#layout {
  display: flex;
  min-height: 100vh;
}

#side {
  flex: 1;
}

.map-controller {
  position: relative;
  flex: 2;
}
*/

#side {
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  background: #fff;
  z-index: 2;
  padding: 20px 30px;
  min-height: 100%;
  transform: translateX(-100%);
  transition: transform 0.3s;
}

#side.visible {
  transform: translateX(0);
}

#map, .map-loader {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.map-loader {
    background: #333;
    opacity: .5;
    color: #fff;
    text-align: center;
    padding-top: 50%;
}

.point-properties {
}
.point-info b {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #999;
  display: block;
  margin-top: .5rem;
}
.side-close-button {
  position: absolute;
  top: 25px;
  right: 25px;
}
.side-close-button:hover {
  cursor: pointer;
}

pre {
  font-family: 'Source Code Pro', monospace;
  font-size: 0.9rem;
  color: #999;
  padding: 0;
  margin: 0;
}

h1 pre {
  color: #999;
}

.mapboxgl-popup-content pre {
  font-size: 10px;
}


</style>

<template>
  <MapController />
</template>

<script>
import MapController from './components/MapController.vue'

export default {
  name: 'App',
  components: {
    MapController
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
